/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  APIProvider,
  AdvancedMarker,
  Map,
  useMap,
} from "@vis.gl/react-google-maps";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import {
  BooleanParam,
  NumberParam,
  ObjectParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import chevronLeft from "../../images/icons/chevron-left-map.svg";
import lessSvg from "../../images/icons/less.svg";
import localisationSvg from "../../images/icons/localisation.svg";
import imgMap from "../../images/icons/map-2-line.svg";
import moreSvg from "../../images/icons/more.svg";
import { NotificationService } from "../../services/Notification.service";
import { CheckCompareRef } from "../../services/useCurrentScrollY";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { handleConvertToNumberInObject } from "../../services/utils";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/storeMap.css";
import CardMap from "../cells/CardMap";

const delayRequest = 600;
const panZoomDelay = 0;
function StoreMap({
  refreshStoreSearch,
  dataResidence,
  loading,
  firstLoad,
  setMapStore,
  mapStore,
  setLoadingPage,
}) {
  const [markersResidence, setMarkersResidence] = useState([]);
  const [mapQuery, setMapQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    mapOpen: withDefault(BooleanParam, false),
    centerMap: withDefault(ObjectParam, null),
    mapZoom: withDefault(NumberParam, null),
    mapBounds: withDefault(ObjectParam, null),
    currentMarkerId: withDefault(NumberParam, null),
  });
  const [goToUserPosAfter, setGoToUserPosAfter] = useState(false);
  const storeParameters = useStoreManager((state) => state.storeParameters);
  const handleUpdateStoreParameters = useStoreManager(
    (state) => state.handleUpdateStoreParameters
  );
  const setSelectedMarker = useStorePageManager(
    (state) => state.handleUpdateCurrentMapTouched
  );
  const selectedMarker = useStorePageManager(
    (state) => state.currentMapTouched
  );
  const handleSetLocalisationVoid = useStorePageManager(
    (state) => state.handleUpdateLocateUserOnMap
  );

  const [loadingMap, setLoadingMap] = useState(true);
  const [userPosition, setUserPosition] = useState();
  const [markerTimeout, setMarkerTimeout] = useState();
  const [delayTimeout, setDelayTimeout] = useState();
  const [userPostionActive, setUserPositionActive] = useState(false);

  const [checkPosition, setCheckPosition] = useState(false);
  const INITIAL_CAMERA = {
    center: { lat: 5.384071856665227, lng: -3.9552928527905684 },
    zoom: 14,
  };

  useEffect(() => {
    if (!dataResidence) return;
    setLoadingMap(false);
    if (!Array.isArray(dataResidence.data)) {
      return setMarkersResidence([]);
      const newDataResidence = dataResidence.data.residences || [];
      //si selectedMarker est null, on retourne newDataResidence
      if (!selectedMarker) return setMarkersResidence(newDataResidence);

      return setMarkersResidence(newDataResidence);
    }
    const newDataResidence = dataResidence.data || [];
    if (!selectedMarker) return setMarkersResidence(newDataResidence);
    if (!newDataResidence.find((item) => item.id === selectedMarker?.id))
      return setMarkersResidence([...newDataResidence, selectedMarker]);
    return setMarkersResidence(newDataResidence);
  }, [dataResidence]);
  const locationParams = new URLSearchParams(window.location.search);
  const mapOpen = locationParams.get("mapOpen");
  if (!mapOpen) {
    mapQuery.currentMarkerId = null;
  }
  useEffect(() => {
    const currentData = useStoreManager.getState().searchStoreParams;
    if (!mapStore) return;
    if (
      mapStore &&
      currentData.lng &&
      currentData.lat &&
      Object.keys(mapStore).length
    ) {
      try {
        mapStore.setZoom(15);
        mapStore.panTo({
          lat: currentData.lat,
          lng: currentData.lng,
        });

        handleUpdateStoreParameters({
          ...storeParameters,
          lng: null,
          lat: null,
        });
      } catch (error) {
        console.error("error", error);
      }
    }
  }, [loading, mapStore]);
  useEffect(() => {
    if (!mapStore) return;
    if (checkPosition) return;
    if (!mapOpen || mapOpen === "false" || mapOpen === "0") {
      setCheckPosition(true);
      return;
    }
    //si mapStore est un objet vide, on ne fait rien
    try {
      if (!Object.keys(mapStore).length || !mapStore) return;
      let timeoutSave = null;
      if (mapQuery.mapBounds) {
        const bounds = handleConvertToNumberInObject(mapQuery.mapBounds);
        timeoutSave = setTimeout(() => {
          mapStore.fitBounds(bounds);
          setTimeout(() => {
            mapStore.setZoom(parseInt(mapQuery.mapZoom || "16"));
          }, 300);
        }, panZoomDelay);
      } else if (mapQuery.centerMap) {
        const center = handleConvertToNumberInObject(mapQuery.centerMap);
        timeoutSave = setTimeout(() => {
          mapStore.moveCamera({
            center: center,
            zoom: parseInt(mapQuery.mapZoom || "16"),
          });
        }, panZoomDelay);
      }

      setCheckPosition(true);
      return () => {
        if (timeoutSave) clearTimeout(timeoutSave);
      };
    } catch (error) {
      console.error("error", error);
    }
  }, [mapStore]);
  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);
  const storeMap = useStorePageManager((state) => state.storeMap);

  const toggleOpenStoreMap = () => {
    setMapQuery({ mapOpen: true }, "replaceIn");
    //retirer la barre url de l'iphone

    handleUpdateStoreParameters({ ...storeParameters, mapOpen: true });
    useStorePageManager.getState().handleUdpateStoreMap(true);
    useStorePageManager.getState().handleUpdateBodyOverflow(false);
  };

  const toggleCloseStoreMap = async () => {
    setLoadingPage(true);
    if (intervalLocation.current) clearInterval(intervalLocation.current);
    // console.log("close map", storeParameters);
    const viewPort = {
      northeast: {
        lat: cameraProps.bounds.north,
        lng: cameraProps.bounds.east,
      },
      southwest: {
        lat: cameraProps.bounds.south,
        lng: cameraProps.bounds.west,
      },
    };
    handleUpdateStoreParameters({
      ...storeParameters,
      viewPort,
      mapOpen: false,
    });
    await useStoreManager.getState().handleUpdateSearchStoreParameters();
    // console.log("close map", storeParameters);
    useStorePageManager.getState().handleUdpateStoreMap(false);
    useStorePageManager.getState().handleUpdateBodyOverflow(true);
    if (refreshStoreSearch) refreshStoreSearch();
    setMapQuery({ mapOpen: false }, "replaceIn");
    setMapStore(null);
  };

  useEffect(() => {
    if (mapQuery.mapOpen) toggleOpenStoreMap();
    return () => {
      useStorePageManager.getState().handleUpdateWhaBlock(false);
      if (markerTimeout) clearTimeout(markerTimeout);
    };
  }, []);

  const handleUpdateCameraParams = (ev) => {
    try {
      if (!mapStore) return;
      // handleUpdateStoreParameters({
      //   ...storeParameters,
      //   destination: "",
      // });
      if (!checkPosition) return;
      if (!ev) return;
      //si ev est un objet vide, on ne fait rien
      if (!Object.keys(ev).length) return;
      const details = ev.detail || null;
      if (!details) return;

      setMapQuery(
        {
          centerMap: ev.detail.center,
          mapBounds: ev.detail.bounds,
          mapZoom: ev.detail.zoom,
        },
        "replaceIn"
      );
      setCameraProps(ev.detail);
    } catch (error) {}
  };
  //check if i am in old lastResponse

  //select a marker
  const handleSelectMarker = (marker) => {
    try {
      if (selectedMarker === marker) {
        useStorePageManager.getState().handleUpdateWhaBlock(false);
        setMapQuery(
          {
            currentMarkerId: null,
          },
          "replaceIn"
        );
        return setSelectedMarker(null);
      }
      if (markerTimeout) clearTimeout(markerTimeout);
      if (selectedMarker) {
        setSelectedMarker(null);
        setMarkerTimeout(
          setTimeout(() => {
            setMapQuery(
              {
                currentMarkerId: marker.id,
              },
              "replaceIn"
            );
            setSelectedMarker(marker);
          }, 150)
        );
      } else {
        useStorePageManager.getState().handleUpdateWhaBlock(true);
        setMapQuery(
          {
            currentMarkerId: marker.id,
          },
          "replaceIn"
        );
        setSelectedMarker(marker);
      }
    } catch (error) {}
  };

  const handleZoomIn = () => {
    if (!mapStore) return;
    try {
      if (!Object.keys(mapStore).length || !mapStore) return;
      const newZoom = mapStore.getZoom() + 1;
      mapStore.setZoom(newZoom);
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleZoomOut = () => {
    try {
      if (!mapStore) return;
      if (!Object.keys(mapStore).length || !mapStore) return;
      const newZoom = mapStore.getZoom() - 1;
      mapStore.setZoom(newZoom);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    setLoadingMap(true);
    const updateSearchStore = async () => {
      handleUpdateStoreParameters({
        ...storeParameters,
        mapOpen: false,
        // centerMap: null,
        mapZoom: null,
        bounds: null,
        page: 1,
      });
      setMapQuery(
        {
          page: 1,
          mapOpen: false,
          // centerMap: null,
          mapZoom: null,
          mapBounds: null,
        },
        "replaceIn"
      );
      await useStoreManager.getState().handleUpdateSearchStoreParameters();
      if (refreshStoreSearch) refreshStoreSearch();
    };
    if (!mapQuery.mapZoom) return;
    if (mapQuery.mapOpen) return;
    updateSearchStore();
  }, [mapQuery.mapOpen]);

  //recuperer la position de l'utilisateur, si erreur, afficher un message d'erreur
  const getGeolocation = (goToPos = false, openMap = false) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        };
        if (openMap) toggleOpenStoreMap();

        setUserPositionActive(true);
        setUserPosition(pos);

        //si mapStore est un objet vide, on ne fait rien
        if (!mapStore || openMap) {
          setGoToUserPosAfter(true);
          return;
        }
        if (!userPosition || goToPos) {
          mapStore.setZoom(16);
          mapStore.panTo({
            lat: pos?.lat,
            lng: pos?.lng,
          });

          if (!userPosition)
            NotificationService.addToQueue(0, "Position récupérée avec succès");
        }
        // mapStore.moveCamera({ center: pos, zoom: 18 });
      },
      () => {
        NotificationService.addToQueue(
          2,
          "Erreur lors de la récupération de votre position"
        );
      }
    );
  };
  const intervalLocation = useRef(null);
  useEffect(() => {
    if (!mapStore) return;
    if (intervalLocation.current) clearInterval(intervalLocation.current);
    if (!mapOpen) {
      setUserPosition(null);
      return;
    }
    if (!userPostionActive) return;
    const interval = setInterval(() => {
      if (!mapQuery.mapOpen) {
        return clearInterval(interval);
      }
      getGeolocation();
    }, 3500);
    intervalLocation.current = interval;
    return () => clearInterval(interval);
  }, [mapOpen, mapStore, userPostionActive]);
  const handleLocateUser = (openMap = false) => {
    //demander l'autorisation de l'utilisateur
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        if (openMap) toggleOpenStoreMap();
        getGeolocation(true, openMap);
      } else if (result.state === "prompt") {
        if (openMap) toggleOpenStoreMap();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            };
            setUserPositionActive(true);
            setUserPosition(pos);
            if (!mapStore || Object.keys(mapStore).length == 0 || openMap) {
              setGoToUserPosAfter(true);
              return;
            }
            mapStore.setZoom(18);
            mapStore.panTo({
              lat: pos.lat,
              lng: pos.lng,
            });
          },
          (error) => {
            console.error("Erreur de géolocalisation :", error.message);
          }
        );
      } else if (result.state === "denied") {
        NotificationService.addToQueue(
          1,
          "Vous avez refusé la géolocalisation."
        );
        NotificationService.addToQueue(
          1,
          "Veuillez l'activer dans les paramètres de votre navigateur."
        );
      }
    });
  };
  useEffect(() => {
    if (goToUserPosAfter) {
      getGeolocation(true);
      setGoToUserPosAfter(false);
    }
    handleSetLocalisationVoid(handleLocateUser);
  }, [mapStore, goToUserPosAfter]);

  useEffect(() => {
    try {
      if (!mapQuery.mapOpen) return;
      if (!mapStore) return;
      if (delayTimeout) clearTimeout(delayTimeout);
      handleUpdateStoreParameters({
        ...storeParameters,
        mapOpen: mapQuery.mapOpen,
        centerMap: cameraProps.center,
        mapZoom: cameraProps.zoom,
        mapBounds: cameraProps.bounds,
      });
      setLoadingMap(true);
      const timeout = setTimeout(async () => {
        await useStoreManager.getState().handleUpdateSearchStoreParameters();
        if (refreshStoreSearch) refreshStoreSearch();
      }, delayRequest);
      setDelayTimeout(timeout);
      return () => clearTimeout(timeout);
    } catch (error) {}
  }, [cameraProps]);

  const mapOptions = {
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,

    mapId: "4676e62fc3d9bda7",
    // mapId: "8e0a97af9386fef",

    minZoom: 5,
    maxZoom: 20,
    clickableIcons: false,
    draggableCursor: "default",
    scrollwheel: true,
    disableDefaultUI: true,
    gestureHandling: "greedy",
  };
  const { stateCompare, refFromTop1, refFromTop2 } = CheckCompareRef(
    "btn-showcarte",
    "footer",
    1000,
    200
  );
  return (
    <section className="store-map">
      <AnimatePresence mode="sync">
        {!storeMap && (
          <motion.button
            id="btn-showcarte"
            initial={{ opacity: 0, translateY: "20px", translateX: "-50%" }}
            animate={{
              opacity: refFromTop1 > refFromTop2 ? 0 : 1,
              userSelect: refFromTop1 > refFromTop2 ? "none" : "auto",
              pointerEvents: refFromTop1 > refFromTop2 ? "none" : "auto",
              translateY: 0,
              translateX: "-50%",
              transition: { duration: 0.3, delay: 0.5, ease: "easeInOut" },
            }}
            exit={{
              opacity: 0,
              translateY: "20px",
              translateX: "-50%",
              userSelect: "none",
            }}
            className={`btn-open-map ${!storeMap ? "open" : "close"}`}
            onClick={toggleOpenStoreMap}
          >
            <img src={imgMap} alt="map-icon" />
            <span>Afficher la carte</span>
          </motion.button>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {storeMap && (
          <motion.div
            initial={{ opacity: 0, translateY: "100%" }}
            animate={{
              opacity: 1,
              translateY: 0,
              transition: { duration: 0.3, ease: "easeInOut" },
            }}
            exit={{
              opacity: 0,
              translateY: "100%",
              transition: { duration: 0.2, ease: "easeInOut" },
            }}
            className="store-map-container"
          >
            <button
              className="btn close-listing"
              onClick={() => {
                toggleCloseStoreMap();
              }}
            >
              <img src={chevronLeft} alt="chevron-left" />
              <span>Fermer la carte</span>
            </button>
            <div className="container-map">
              <div className="map-controls">
                <button onClick={handleZoomIn}>
                  <img src={moreSvg} alt="more-icon" />
                </button>
                <button onClick={handleZoomOut}>
                  <img src={lessSvg} alt="less-icon" />
                </button>
                <button onClick={handleLocateUser}>
                  <img
                    style={{
                      transform: "translate(-1px, 1px)",
                    }}
                    src={localisationSvg}
                    alt="localisation-icon"
                  />
                </button>
              </div>
              <AnimatePresence>
                {(loading || loadingMap) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0.2 } }}
                    className="loading-map flex liner"
                  >
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAP_ADD}
                region="CI"
                language="FR"
              >
                <Map
                  style={{
                    width: "100%",
                    height: "100%",
                    outline: "none",
                    border: "none",
                  }}
                  onBoundsChanged={(e) => handleUpdateCameraParams(e)}
                  onClick={() => {
                    useStorePageManager.getState().handleUpdateWhaBlock(false);
                    setMapQuery(
                      {
                        currentMarkerId: null,
                      },
                      "replaceIn"
                    );
                    setSelectedMarker(null);
                  }}
                  {...cameraProps}
                  {...mapOptions}
                >
                  {userPosition && mapStore && (
                    <AdvancedMarker
                      className="marker-pos"
                      position={userPosition}
                      zIndex={1}
                    >
                      <></>
                    </AdvancedMarker>
                  )}
                  {!firstLoad && (
                    <RenderMarker
                      markers={
                        markersResidence.length === 0
                          ? selectedMarker
                            ? [selectedMarker]
                            : []
                          : markersResidence
                      }
                      map={mapStore}
                      loading={loading || loadingMap}
                      handleSelectMarker={handleSelectMarker}
                      currentMarker={selectedMarker}
                      mapZoom={cameraProps.zoom}
                      dataResidence={dataResidence}
                      currentMarkerId={mapQuery.currentMarkerId}
                    />
                  )}
                </Map>
                {!mapStore && <GetMapFunction setMapStore={setMapStore} />}
              </APIProvider>
              <CardMap
                data={selectedMarker}
                handleClose={() => {
                  useStorePageManager.getState().handleUpdateWhaBlock(false);
                  setMapQuery(
                    {
                      currentMarkerId: null,
                    },
                    "replaceIn"
                  );
                  setSelectedMarker(null);
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}
const RenderMarker = ({
  markers,
  handleSelectMarker,
  currentMarker,
  mapZoom,
  map,
  dataResidence,
  loading = true,
  currentMarkerId,
}) => {
  // console.log("markers", markers);
  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);
  const sign = useStoreCurrencies((state) => state.sign);
  const myCurrentMarkersList =
    useStorePageManager.getState().currentMarkersList;
  const handleUpdateMarkersList = useStorePageManager(
    (state) => state.handleUpdateCurrentMarkersList
  );

  useEffect(() => {
    if (!markers) return;
    if (currentMarkerId) {
      //find the marker with the id and select it
      const marker = markers.find((item) => item.id === currentMarkerId);
      if (marker && marker !== currentMarker) {
        if (marker.id === currentMarker?.id) return;
        console.log("marker", marker);
        handleSelectMarker(marker);
      }
    }
    if (markers.length == 1 && markers[0] == currentMarker && loading) return;
    handleUpdateMarkersList(markers);
  }, [markers, loading]);
  const [currentHover, setCurrentHover] = useState(null);
  const returnMyPosition = (marker) => {
    return {
      lat: parseFloat(marker.lat.toString()),
      lng: parseFloat(marker.lng.toString()),
    };
  };
  // if (myCurrentMarkersList?.length > 0) {
  //   console.log(myCurrentMarkersList[4]);
  // }
  return (
    <>
      {markers.length == 1 &&
      markers[0] == currentMarker &&
      loading === true &&
      myCurrentMarkersList.length > 0
        ? myCurrentMarkersList.map((marker, index) => (
            <AdvancedMarker
              key={index}
              position={returnMyPosition(marker)}
              zIndex={
                currentMarker?.id === marker.id
                  ? 1
                  : currentHover === marker.id
                  ? 1
                  : 0
              }
            >
              <motion.div
                onClick={() => handleSelectMarker(marker)}
                onMouseOver={() => {
                  setCurrentHover(marker.id);
                }}
                initial={{
                  opacity: currentMarker ? 1 : 0,
                  scale: currentMarker ? 1 : 0.5,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.2,
                    delay: mapZoom > 16 ? 0 : 0.01 * index,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.05 },
                }}
                className={`marker-map ${
                  marker.id === currentMarker?.id ? "selected" : ""
                } ${currentHover === marker.id ? "hovered" : ""}`}
              >
                <span className="marker-title">
                  {handleFormatToLocalString(
                    marker.preview?.averagePricePerNight +
                      marker.preview?.oneDayFee,
                    language,
                    sign
                  ) +
                    " " +
                    showDevise}
                </span>
              </motion.div>
            </AdvancedMarker>
          ))
        : markers.map((marker, index) => (
            <AdvancedMarker
              onClick={(e) => handleSelectMarker(marker, e)}
              key={index}
              position={returnMyPosition(marker)}
              zIndex={
                currentMarker?.id === marker.id
                  ? 1
                  : currentHover === marker.id
                  ? 1
                  : 0
              }
            >
              <motion.div
                onMouseOver={() => {
                  setCurrentHover(marker.id);
                }}
                initial={{
                  opacity: currentMarker ? 1 : 0,
                  scale: currentMarker ? 1 : 0.5,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.2,
                    delay: mapZoom > 16 ? 0 : 0.01 * index,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.05 },
                }}
                className={`marker-map ${
                  marker.id === currentMarker?.id ? "selected" : ""
                } ${currentHover === marker.id ? "hovered" : ""}`}
              >
                <span className="marker-title">
                  {handleFormatToLocalString(
                    marker.preview?.averagePricePerNight +
                      marker.preview?.oneDayFee,
                    language,
                    sign
                  ) +
                    " " +
                    showDevise}
                </span>
              </motion.div>
            </AdvancedMarker>
          ))}
      {}
    </>
  );
};

export default StoreMap;

const GetMapFunction = ({ setMapStore }) => {
  const map = useMap();
  useEffect(() => {
    if (!map) return;
    setMapStore(map);
  }, [map]);

  return <></>;
};
